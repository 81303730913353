<template
  ><v-container v-if="post" class="we-love-trees-detail">
    <!--TO DO: BREADCRUMBS-->
    <h1 v-html="post.title.rendered"></h1>
    <v-row class="my-4">
      <v-col cols="12" md="8"
        ><v-img class="rounded-md" :src="post.featured_image_url"
      /></v-col>
      <v-col cols="12" md="4">
        <v-card class="white rounded-md">
          <h4
            class="text-uppercase green darken-1 rounded-md rounded-b-0 white--text px-5 py-2"
          >
            {{ post.category_names[2] || "We Love Trees" }}
          </h4>
          <v-list-item class="py-2 px-5 mb-3">
            <v-list-item-content class="py-0">
              <v-list-item-title class="mb-1 text-uppercase font-weight-bold">{{
                $t("weLoveTrees.detail.place")
              }}</v-list-item-title>
              <v-list-item-subtitle>{{ post.indirizzo }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="py-2 px-5 mb-3">
            <v-list-item-content class="py-0">
              <v-list-item-title class="mb-1 text-uppercase font-weight-bold">{{
                $t("weLoveTrees.detail.comune")
              }}</v-list-item-title>
              <v-list-item-subtitle>{{ post.citta }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="py-2 px-5 mb-3">
            <v-list-item-content class="py-0">
              <v-list-item-title class="mb-1 text-uppercase font-weight-bold">{{
                $t("weLoveTrees.detail.province")
              }}</v-list-item-title>
              <v-list-item-subtitle>{{
                provinceNames[post.provincia]
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="py-2 px-5 mb-3">
            <v-list-item-content class="py-0">
              <v-list-item-title class="mb-1 text-uppercase font-weight-bold">{{
                $t("weLoveTrees.detail.firstTreePlanted")
              }}</v-list-item-title>
              <v-list-item-subtitle>{{
                $dayjs(post.data).format("DD/MM/YYYY")
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="my-4">
      <v-col cols="12"
        ><div class="pa-4 white rounded-md" v-html="post.content.rendered"></div
      ></v-col>
    </v-row>
    <v-row class="my-4">
      <v-col cols="12" md="8">
        <GmapMap
          class="rounded-md"
          :style="{ width: mapWidth, height: mapHeight }"
          ref="mapRef"
          :center="{
            lat: Number(post.latitudine),
            lng: Number(post.longitudine)
          }"
          :zoom="Number(13)"
          :options="{
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            disableDefaultUI: false
          }"
        >
          <GmapMarker
            :ref="'wlt_' + post.id"
            :position="
              google &&
                new google.maps.LatLng(post.latitudine, post.longitudine)
            "
          /> </GmapMap
      ></v-col>
      <v-col cols="12" md="4">
        <v-row class="d-flex flex-column">
          <v-col cols="12">
            <DonationCard></DonationCard>
          </v-col>
          <v-col cols="12">
            <InitiativesCard></InitiativesCard>
          </v-col>
        </v-row>
      </v-col>
    </v-row> </v-container
></template>
<style lang="scss">
.we-love-trees-detail {
  .vue-map-container .vue-map {
    border: 1px solid var(--v-grey-lighten1);
    border-radius: 12px;
  }
}
</style>
<script>
import cMService from "~/service/cmService";
import { gmapApi } from "vue2-google-maps";
import DonationCard from "@/components/wordpress/DonationCard.vue";
import InitiativesCard from "@/components/wordpress/InitiativesCard.vue";
export default {
  name: "WeLoveTreeDetail",
  data() {
    return {
      post: null,
      provinceNames: {
        RO: "Rovigo",
        PD: "Padova",
        TV: "Treviso",
        FE: "Ferrara",
        VI: "Vicenza",
        VE: "Venezia",
        BL: "Belluno"
      }
    };
  },
  components: { DonationCard, InitiativesCard },
  computed: {
    google: gmapApi,
    mapWidth() {
      return "auto";
    },
    mapHeight() {
      return this.$vuetify.breakpoint.xsOnly ? "300px" : "465px";
    }
    /* pageContent() {
      return this.page && this.page.content ? this.page.content.rendered : "";
    },
    pageTitle() {
      return this.page && this.page.title ? this.page.title.rendered : "";
    },*/
  },
  methods: {
    async fetchBlog(slug) {
      const res = await cMService.getCustomPostBySlug("we_love_trees", slug);
      if (res && res.length) {
        this.post = res[0];
      }
    }
  },
  mounted() {
    this.fetchBlog(this.$route.params.postName);
  },
  watch: {
    "$route.params.postName"() {
      this.fetchBlog(this.$route.params.postName);
    }
  }
};
</script>
